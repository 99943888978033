import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';

const sliderImageUrl = [

  { URL: "https://i0.wp.com/diariodotransporte.com.br/wp-content/uploads/2023/12/Grupo-Guanabara.jpg?fit=1024%2C768&ssl=1", alt: "Guanabara" },
  { URL: "https://i.ytimg.com/vi/vrK9Z2Irl-Y/maxresdefault.jpg", alt: "Real Expresso" },
  { URL: "https://scontent-bsb1-1.xx.fbcdn.net/v/t39.30808-6/302015863_449919230531778_1604775265649941401_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=cc71e4&_nc_ohc=VBJ2s_OBZJoQ7kNvgHVjDY3&_nc_ht=scontent-bsb1-1.xx&_nc_gid=ATuKaU3HZ7Jdp3GFDwSIoml&oh=00_AYCeWqjlPKh_2cFmDh2ANGSAC22f2KSc7HjMZ3gChsnmvQ&oe=6712EEA0", alt: "Util" },
  { URL: "https://www.expressouniao.com.br/dw/image/v2/BDZL_PRD/on/demandware.static/-/Sites-expresso-uniao-Library/default/dwe9c833ed/-1.jpg", alt: "Expresso União" },
  { URL: "https://onibusetransporte.com/wp-content/uploads/2024/05/IMG-20240414-WA0119.jpg", alt: "Catedral" },
  { URL: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEh8L9ugU2GXU71eiPRk9tKmz5paLe5_EkK7mjQsFCYtKHInYPlus9qJiAZH2tilFrd5GX_7LIDjcWTh7TuohnUH2iprVPBieva9rQzoVF8AqyXKVWtq77h_3WlPNORWEtci7MlLf9mVJgRdOlH-6btmKFE_Z76UNilARx2I663uZCOjQBQmZGqczuZS5sU7/w1200-h630-p-k-no-nu/927%20Via%C3%A7%C3%A3o%20Total_Marcopolo%20Paradiso%20New%20G7%201800%20DD_Mercedes%20Benz%20O500RSD%20Blue%20Tec%205.jpg", alt: "Total" },
  { URL: "https://i.ytimg.com/vi/UQY1bysxKTo/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLCLXj4HGpl-0J7BI-kdkqBGOZIneg", alt: "RealSul" },
  { URL: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgbkri2Bu0OyT5I5ytuoFAavgfBUi8orJk653DNglSq9yYgx63_6c1iv7wvUbaDJ1IBZtJLYlDLJjOPwg-8eRdxm-UjW-2TebzyeNO1JNm0WdLtL8q8pPPr_RYShYWFRbpxFY41_O8FmbFoNbsdJtQHnTns8H4se59H_b9N8h7N5Z5P141CkzoEcTCfC15F/s1024/dsrotas2206.jpg", alt: "RodeRotas" },
  { URL: "https://www.quetalviajar.com/images/rodoviario/viacao-motta-01.jpg", alt: "Motta" },
  { URL: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjX3I4Hbxzo0VLIqXOsmMNuqgZPJbZ8NWg5z1ts0rGgWF61qVDcOEQ880SSrHTnFrq0PiHmDCWcNDKwpfm9ZYjByMJuLq3smN-SrbpUQZ52vWVuRuhMjIPiVde77FqCylJ7YviXCrpdYu64cU8IbfeFSWlsMzuMLNm2nOnlpS5XNKE44lA0dowsNJE3v88/w640-h456/_MG_0453%20-%20Copia.JPG", alt: "Itapemirim" },
  { URL: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjafIAxUB4anA8vjeois7gG16u6ALR8EW0CF2C4tSk3KFu0Qr4PtFjb8Dr6j3n31junQEgsCk2XoODaAYdykkydMbTgazVsQ8-us4q4zfBC_Mm0AOTZCqKtbT1N8gMkpJdCZ5sA8KY3OWWCjMB79GTMOTwtJfmgg82yhRq4L1PnGbst8l_Xa-wxaTnqfq4k/w1200-h630-p-k-no-nu/dscantelle7195.jpg", alt: "Cantelle" },
  { URL: "https://onibusetransporte.com/wp-content/uploads/2024/06/IMG-20240617-WA0130.jpg", alt: "Emtram" },
  { URL: "https://onibusetransporte.com/wp-content/uploads/2023/05/IMG-20230511-WA0019.jpg", alt: "Viação Estrela" },
  { URL: "https://www.princesadonorte.com.br/on/demandware.static/-/Sites-princesa-Library/default/dwcaa91dc3/galery/sl-09.jpg", alt: "Princesa do Norte" },
  { URL: "https://agenciasertao.com/wp-content/uploads/2023/12/Onibus-viacao-Novo-Horizonte.jpg", alt: "Novo Horizonte" },
  { URL: "https://reunidas.com.br/assets/img/noticias/passagem-de-onibus-rs-para-sc.png", alt: "Reunidas" },
  { URL: "https://i.pinimg.com/736x/d0/6f/91/d06f91f6206cff4697730c458f494bd6.jpg", alt: "Transbrasiliana" },
  { URL: "https://i.ytimg.com/vi/BmBPYDy_mGQ/maxresdefault.jpg", alt: "Rápido Marajó" },
  { URL: "https://scontent-bsb1-1.xx.fbcdn.net/v/t39.30808-6/383850258_805073014703960_9018357354368537507_n.jpg?stp=dst-jpg_s960x960&_nc_cat=111&ccb=1-7&_nc_sid=2285d6&_nc_ohc=A0CsZlsZWt4Q7kNvgFK3HCP&_nc_ht=scontent-bsb1-1.xx&_nc_gid=AfuowW8NiJ0vmHGSnyP100M&oh=00_AYC4uzGRO_BF1Ppyc7yZcXdth0CSHsvOfvVF_oTlAbD2TQ&oe=6712F75A", alt: "Andorinha" },
  { URL: "https://agazetadovale.com.br/wp-content/uploads/2018/06/28467724_-Xavante.jpg", alt: "Xavante" },


];

// Styled Components
const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: auto;
  overflow: hidden;
`;

const CarouselWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  transform: ${({ currentIndex }) => `translateX(-${currentIndex * 100}%)`};
`;

const CarouselSlide = styled.div`
  min-width: 100%;
  transition: opacity 0.5s ease;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
`;

const CarouselImage = styled.img`
  width: 90%; // Faz a imagem ocupar toda a largura disponível
  height: auto; // Mantém a proporção da imagem
  object-fit: cover; // Garante que a imagem cubra o espaço
  display: block;
  margin: 0 auto; // Centraliza a imagem
  transition: transform 0.3s ease;

  // Adicionando estilos para telas de toque
  @media (hover: none) {
    cursor: pointer; // Muda o cursor para indicar que a imagem é clicável
  }
`;
const NavigationButton = styled.button`
  position: absolute;
  top: 50%;
  ${({ direction }) => (direction === 'prev' ? 'left: 10px;' : 'right: 10px;')}
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.6);
  border: none;
  cursor: pointer;
  z-index: 1;
  font-size: 24px;
  padding: 10px;
  border-radius: 50%;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }
`;
const DotsContainer = styled.div`
  text-align: center;
  margin-top: 10px;
`;

const Dot = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: ${({ isActive }) => (isActive ? '#333' : '#ccc')};
  border-radius: 50%;
  cursor: pointer;
`;

const CarouselEmpresas = ({ autoSlideInterval = 3000, resumeInterval = 2000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDebounced, setIsDebounced] = useState(false);
  const [carouselState, setCarouselState] = useState({ isPaused: false, isHovered: false });
  const [isTouched, setIsTouched] = useState(false);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % sliderImageUrl.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? sliderImageUrl.length - 1 : prevIndex - 1
    );
  };

  const handleNextSlide = () => {
    if (isDebounced) return;
    setIsDebounced(true);
    nextSlide();
    setTimeout(() => setIsDebounced(false), 300);
  };

  const handlePrevSlide = () => {
    if (isDebounced) return;
    setIsDebounced(true);
    prevSlide();
    setTimeout(() => setIsDebounced(false), 300);
  };

  const handleImageClick = () => {
    setCarouselState((prev) => ({ ...prev, isPaused: true }));
    setTimeout(() => {
      setCarouselState((prev) => ({ ...prev, isPaused: false }));
    }, resumeInterval);
  };

  useEffect(() => {
    if (carouselState.isHovered || carouselState.isPaused) return;
    const interval = setInterval(nextSlide, autoSlideInterval);
    return () => clearInterval(interval);
  }, [carouselState.isHovered, carouselState.isPaused, autoSlideInterval]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') nextSlide();
      if (event.key === 'ArrowLeft') prevSlide();
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  const handlers = useSwipeable({
    onSwipedLeft: nextSlide,
    onSwipedRight: prevSlide,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handleImageTouchStart = () => {
    setIsTouched(true);
  };

  const handleImageTouchEnd = () => {
    setIsTouched(false);
  };

  return (
    <CarouselContainer
      {...handlers}
      onMouseEnter={() => setCarouselState((prev) => ({ ...prev, isHovered: true }))}
      onMouseLeave={() => setCarouselState((prev) => ({ ...prev, isHovered: false }))}
      aria-live="polite"
    >
      <CarouselWrapper currentIndex={currentIndex}>
        {sliderImageUrl.map((image, index) => (
          <CarouselSlide key={index} isActive={index === currentIndex}>
            <CarouselImage
              src={image.URL}
              alt={image.alt}
              loading="lazy"
              onClick={handleImageClick}
              onTouchStart={handleImageTouchStart}
              onTouchEnd={handleImageTouchEnd}
              style={{ transform: isTouched ? 'scale(1.05)' : 'scale(1)' }}
            />
          </CarouselSlide>
        ))}
      </CarouselWrapper>

      <NavigationButton
        direction="prev"
        onClick={handlePrevSlide}
        aria-label="Previous slide"
        role="button"
      >
        &#10094;
      </NavigationButton>
      <NavigationButton
        direction="next"
        onClick={handleNextSlide}
        aria-label="Next slide"
        role="button"
      >
        &#10095;
      </NavigationButton>

      <DotsContainer>
        {sliderImageUrl.map((_, index) => (
          <Dot
            key={index}
            isActive={index === currentIndex}
            onClick={() => setCurrentIndex(index)}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </DotsContainer>
    </CarouselContainer>
  );
};

CarouselEmpresas.propTypes = {
  autoSlideInterval: PropTypes.number,
  resumeInterval: PropTypes.number,
};

export default CarouselEmpresas;